// React
import React from 'react';

// Packages
import t from 'prop-types';
import cc from 'classcat';

// Components
import Card from 'components/_cardContentElements';

const CardContentDates = ({
    theme,
    className,
    startDate,
    startDateTitle,
    endDate,
    endDateTitle,
}) => {
    return (
        <div className={cc(['mb-16', 'flex w-full space-x-16', className])}>
            <Card.CardContentSectionBox
                {...{
                    theme,
                    title: startDateTitle,
                }}>
                <p>{startDate ? startDate : '-'}</p>
            </Card.CardContentSectionBox>
            <Card.CardContentSectionBox
                {...{
                    theme,
                    title: endDateTitle,
                }}>
                <p>{endDate ? endDate : '-'}</p>
            </Card.CardContentSectionBox>
        </div>
    );
};

CardContentDates.propTypes = {
    /* Adds additional class names */
    className: t.string,
    /* Theme */
    theme: t.oneOf(['blue', 'teal']),
    /* Data */
    startDate: t.string,
    startDateTitle: t.string,
    endDate: t.string,
    endDateTitle: t.string,
};

CardContentDates.defaultProps = {
    theme: 'teal',
    className: '',
    startDate: '',
    startDateTitle: '',
    endDate: '',
    endDateTitle: '',
};

export default CardContentDates;
