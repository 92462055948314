// React
import React from 'react';

// Packages
import t from 'prop-types';
import cc from 'classcat';

const CardContentWrapperComponent = ({ children, className, wrap }) => {
    return (
        <div
            className={cc([
                'flex my-16',
                className,
                {
                    'flex-wrap': wrap,
                    'flex-col': !wrap,
                },
            ])}>
            {children}
        </div>
    );
};

CardContentWrapperComponent.propTypes = {
    /* Adds additional class names */
    className: t.string,
    // Wrap children
    wrap: t.bool,
};

CardContentWrapperComponent.defaultProps = {
    className: '',
    wrap: false,
};

export default CardContentWrapperComponent;
