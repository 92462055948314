// React
import React from 'react';

// Packages
import t from 'prop-types';
import cc from 'classcat';

const CardContentPairBox = ({ children, theme }) => {
    return (
        <div
            className={cc([
                'mb-16',
                'flex flex-col p-16 space-y-8 rounded-8 t-sh7 flex-grow',
                {
                    'bg-teal-10': theme === 'teal',
                    'bg-blue-10': theme === 'blue',
                },
            ])}>
            {children}
        </div>
    );
};

const CardContentPair = ({ label, value, theme }) => {
    return (
        <div className="flex flex-col md:flex-row">
            <span
                className={cc([
                    'md:w-3/12',
                    {
                        'text-teal-60': theme === 'teal',
                        'text-blue-60': theme === 'blue',
                    },
                ])}>
                {label}
            </span>
            <span
                className={cc([
                    {
                        'text-teal-100': theme === 'teal',
                        'text-blue-100': theme === 'blue',
                    },
                ])}>
                {value}
            </span>
        </div>
    );
};

CardContentPairBox.propTypes = {
    /* Theme */
    theme: t.oneOf(['blue', 'teal']),
};

CardContentPairBox.defaultProps = {
    theme: 'teal',
};

CardContentPair.propTypes = {
    /* Theme */
    theme: t.oneOf(['blue', 'teal']),
    label: t.string,
    value: t.string,
};

CardContentPair.defaultProps = {
    theme: 'teal',
    label: '',
    value: '',
};

export { CardContentPair, CardContentPairBox };
