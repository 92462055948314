// React
import React from 'react';

// Utilities

// Components

const SectionHeaderComponent = ({ section }) => {
    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div
            className="relative flex flex-col justify-center w-full h-[500px] report-full-height text-blue-100 bg-coral-20 pt-32 border-t border-dashed border-teal-120 report-split"
            style={{
                pageBreakBefore: 'always',
                pageBreakAfter: 'always',
            }}>
            <div className="absolute flex justify-end w-full pr-48 shrink-0 top-48">
                {section.image && (
                    <img
                        src={section.image}
                        className="max-h-[130px] object-cover"
                    />
                )}
            </div>
            <div className="w-full flex h-[300px] space-x-128 justify-between page-px">
                <div className="flex flex-col justify-center">
                    <h2 className="t-pdf-h1">{section.title}</h2>
                    <p
                        className="w-2/3 t-body"
                        dangerouslySetInnerHTML={{
                            __html: section.byline,
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

SectionHeaderComponent.propTypes = {};

SectionHeaderComponent.defaultProps = {};

export default SectionHeaderComponent;
