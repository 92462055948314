// React
import React from 'react';

// Packages

// Utilities
import {
    useLabels,
    useInitiativeGetters,
    useInitiativeAndReportData,
} from 'utilities/hooks';
import {
    useInitiativeDataStore,
    useReportNavigationStore,
} from 'utilities/store';

// Components
import HeaderImage from './headerImage';

// Normal
import About from './about';
import SectionHeader from './sectionHeader';
import SectionOverview from './sectionOverview';
import SectionInputs from './sectionInputs';
import SectionActivities from './sectionActivities';
import SectionResults from './sectionResults';
import SectionLearnings from './sectionLearnings';

const Report_5_0_PDFComponent = () => {
    // ///////////////////
    // STORES
    // ///////////////////

    const { CONSTANTS } = useInitiativeDataStore();
    const { items } = useReportNavigationStore();

    // ///////////////////
    // HOOKS
    // ///////////////////

    const { wizardSection } = useLabels();

    const {
        initiativeData: initiative,
        reportData: report,
    } = useInitiativeAndReportData();

    const { utilities } = useInitiativeGetters(initiative);

    // ///////////////////
    // DATA
    // ///////////////////

    // Based on section in config
    const sectionComponents = {
        Overview(props) {
            return <SectionOverview {...props} />;
        },
        Inputs(props) {
            return <SectionInputs {...props} />;
        },
        Activities(props) {
            return <SectionActivities {...props} />;
        },
        Results(props) {
            return <SectionResults {...props} />;
        },
        Learnings(props) {
            return <SectionLearnings {...props} />;
        },
    };

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        initiative &&
        utilities && (
            <>
                <div
                    style={{
                        pageBreakAfter: 'always',
                    }}>
                    <HeaderImage {...{ initiative }} />
                    <About
                        {...{
                            initiative,
                            report,
                            utilities,
                        }}
                    />
                </div>
                {items.map(parent => {
                    const section = wizardSection(parent.id);
                    return (
                        <>
                            <SectionHeader
                                key={parent.id}
                                {...{ id: parent.id, section }}
                            />
                            {sectionComponents[parent.section]({
                                utilities,
                                CONSTANTS,
                                items: parent.items,
                                wrapCardContents: true,
                            })}
                        </>
                    );
                })}
            </>
        )
    );
};

Report_5_0_PDFComponent.propTypes = {};

Report_5_0_PDFComponent.defaultProps = {};

export default Report_5_0_PDFComponent;
