// React
import React from 'react';

// Packages
import t from 'prop-types';

// Utilities
import { useLabels, useFormat } from 'utilities/hooks';

// Components
import Card from 'components/_cardContentElements';

const ResultsInfluenceOnPolicyComponent = ({
    item,
    theme = 'teal',
    wrap = false,
}) => {
    // ///////////////////
    // DATA
    // ///////////////////

    const { Description__c } = item;

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <Card.CardContentWrapper
            {...{
                wrap,
            }}>
            {/* Description */}
            {Description__c && (
                <Card.CardContentSection
                    {...{
                        theme,
                        wrap,
                    }}>
                    <p>{Description__c}</p>
                </Card.CardContentSection>
            )}

            {/* Details */}
            <ResultsInfluenceOnPolicyDetails {...{ item, theme }} />
        </Card.CardContentWrapper>
    );
};

const ResultsInfluenceOnPolicyDetails = ({ item, theme }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { object, dataSet } = useLabels();
    const format = useFormat();

    // ///////////////////
    // DATA
    // ///////////////////

    const {
        Geographical_Reach__c,
        Additional_Reach_Info__c,
        Influence_Areas__c,
        Year__c,
        URL__c,
    } = item;

    const country = Geographical_Reach__c
        ? dataSet('Countries').find(c => c.value === Geographical_Reach__c)
              ?.label ?? null
        : null;

    const otherLocation = Additional_Reach_Info__c;

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <>
            {/* Various Info */}
            {[Influence_Areas__c, Year__c, URL__c].some(x => x) && (
                <Card.CardContentPairBox>
                    {Influence_Areas__c && (
                        <Card.CardContentPair
                            {...{
                                theme,
                                label: object.label(
                                    'Initiative_Result__c.Influence_Areas__c'
                                ),
                                value: Influence_Areas__c,
                            }}
                        />
                    )}

                    {Year__c && (
                        <Card.CardContentPair
                            {...{
                                theme,
                                label: object.label(
                                    'Initiative_Result__c.Year__c___Influence_On_Policy'
                                ),
                                value: format.year(Year__c),
                            }}
                        />
                    )}

                    {URL__c && (
                        <Card.CardContentPair
                            {...{
                                theme,
                                label: object.label(
                                    'Initiative_Result__c.URL__c___Influence_On_Policy'
                                ),
                                value: URL__c,
                            }}
                        />
                    )}
                </Card.CardContentPairBox>
            )}

            {/* Reach */}
            {[country, otherLocation].some(x => x) && (
                <Card.CardContentSection
                    {...{
                        theme,
                        title: object.label(
                            'Initiative_Result__c.Geographical_Reach__c'
                        ),
                    }}>
                    <p>
                        {otherLocation && otherLocation}
                        {otherLocation && country && `, `}
                        {country && country}
                    </p>
                </Card.CardContentSection>
            )}
        </>
    );
};

ResultsInfluenceOnPolicyComponent.propTypes = {
    item: t.object,
};

ResultsInfluenceOnPolicyComponent.defaultProps = {};

export { ResultsInfluenceOnPolicyDetails };
export default ResultsInfluenceOnPolicyComponent;
