// React
import React from 'react';

// Packages
import t from 'prop-types';
import cc from 'classcat';

const CardContentSectionComponent = ({
    children,
    theme,
    className,
    title,
    actions = null,
    wrap,
}) => {
    return (
        <div
            className={cc([
                'mb-16',
                className,
                {
                    'text-teal-80': theme === 'teal',
                    'text-blue-80': theme === 'blue',
                    'w-1/2': wrap,
                },
            ])}>
            <div className="flex items-center justify-between pr-24">
                <div>
                    {title && (
                        <h5
                            className={cc([
                                't-caption-bold flex items-center w-full',
                                {
                                    'text-teal-60': theme === 'teal',
                                    'text-blue-60': theme === 'blue',
                                },
                            ])}>
                            {title}
                        </h5>
                    )}
                    {children}
                </div>
                {actions && <span className="flex space-x-12">{actions}</span>}
            </div>
        </div>
    );
};

CardContentSectionComponent.propTypes = {
    /* Adds additional class names */
    className: t.string,
    /* Theme */
    theme: t.oneOf(['blue', 'teal']),
};

CardContentSectionComponent.defaultProps = {
    theme: 'teal',
    className: '',
    wrap: false,
};

export default CardContentSectionComponent;
