// React
import React from 'react';

// Packages
import t from 'prop-types';

// Utilities

// Components
import Card from 'components/_cardContentElements';

const ResultsCustomOutputComponent = ({
    item,
    theme = 'teal',
    wrap = false,
}) => {
    // ///////////////////
    // DATA
    // ///////////////////

    const { Description__c } = item;

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <Card.CardContentWrapper
            {...{
                wrap,
            }}>
            {/* Description */}
            {Description__c && (
                <Card.CardContentSection
                    {...{
                        theme,
                        wrap,
                    }}>
                    <p>{Description__c}</p>
                </Card.CardContentSection>
            )}
        </Card.CardContentWrapper>
    );
};

ResultsCustomOutputComponent.propTypes = {
    item: t.object,
};

ResultsCustomOutputComponent.defaultProps = {};

export default ResultsCustomOutputComponent;
