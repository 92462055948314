import create from 'zustand';

const useFilterInitiativeSearch = create(set => ({
    initial: null,
    setInitial: initial => set(() => ({ initial })),

    filtered: null,
    setFiltered: filtered => set(() => ({ filtered })),

    filter: null,
    setFilter: filter => set(() => ({ filter })),

    reset: () => set(() => ({ initial: null, filtered: null, filter: null })),
}));

export { useFilterInitiativeSearch };
