import C from './constants.js';

const { INITIATIVE, ACTIVITIES, RESULTS } = C;

const defaults = {
    activity: {
        indicatorMetricTargetOn: false,
    },
    result: {},
};

export default {
    [INITIATIVE.BASIC]: {
        name: INITIATIVE.BASIC,
        activities: {
            [ACTIVITIES.INTERVENTION]: defaults.activity,
        },
        results: {
            [RESULTS.CUSTOM_OUTPUT]: defaults.result,
        },
    },
    [INITIATIVE.BII]: {
        name: INITIATIVE.BII,
        activities: {
            [ACTIVITIES.ENGAGEMENT]: defaults.activity,
            [ACTIVITIES.DEVELOPMENT]: defaults.activity,
            [ACTIVITIES.TEAM_EDUCATION]: defaults.activity,
            [ACTIVITIES.INTERVENTION]: defaults.activity,
        },
        results: {
            [RESULTS.DISSEMINATION]: defaults.result,
            [RESULTS.KNOWLEDGE]: defaults.result,
            [RESULTS.INFLUENCE_ON_POLICY]: defaults.result,
            [RESULTS.AWARD]: defaults.result,
            [RESULTS.PRODUCTS_AND_INVENTIONS]: defaults.result,
            [RESULTS.INVENTION_DISCLOSURE]: defaults.result,
            [RESULTS.PATENTS]: defaults.result,
            [RESULTS.INTELLECTUAL_PROPERTY]: defaults.result,
            [RESULTS.COMPANY]: defaults.result,
        },
    },
    [INITIATIVE.CULTURE]: {
        name: INITIATIVE.CULTURE,
        activities: {
            [ACTIVITIES.ENGAGEMENT]: defaults.activity,
            [ACTIVITIES.TEACHING]: defaults.activity,
            [ACTIVITIES.DEVELOPMENT]: defaults.activity,
        },
        results: {
            [RESULTS.DISSEMINATION]: defaults.result,
            [RESULTS.INFLUENCE_ON_POLICY]: defaults.result,
            [RESULTS.AWARD]: defaults.result,
        },
    },
    [INITIATIVE.EDUCATION]: {
        name: INITIATIVE.EDUCATION,
        activities: {
            [ACTIVITIES.ENGAGEMENT]: defaults.activity,
            [ACTIVITIES.EO_TEACHING]: defaults.activity,
        },
        results: {
            [RESULTS.DISSEMINATION]: defaults.result,
            [RESULTS.KNOWLEDGE]: defaults.result,
            [RESULTS.AWARD]: defaults.result,
            [RESULTS.INFLUENCE_ON_POLICY]: defaults.result,
        },
    },
    [INITIATIVE.EDUCATION_NOT_NNF]: {
        name: INITIATIVE.EDUCATION_NOT_NNF,
        activities: {
            [ACTIVITIES.ENGAGEMENT]: defaults.activity,
            [ACTIVITIES.TEACHING]: defaults.activity,
        },
        results: {
            [RESULTS.DISSEMINATION]: defaults.result,
            [RESULTS.KNOWLEDGE]: defaults.result,
            [RESULTS.INFLUENCE_ON_POLICY]: defaults.result,
            [RESULTS.AWARD]: defaults.result,
        },
    },
    [INITIATIVE.ENVIRONMENT]: {
        name: INITIATIVE.ENVIRONMENT,
        activities: {
            [ACTIVITIES.ENGAGEMENT]: defaults.activity,
            [ACTIVITIES.TEACHING]: defaults.activity,
            [ACTIVITIES.DEVELOPMENT]: defaults.activity,
            [ACTIVITIES.UPGRADE]: defaults.activity,
            [ACTIVITIES.INTERVENTION]: defaults.activity,
        },
        results: {
            [RESULTS.DISSEMINATION]: defaults.result,
            [RESULTS.KNOWLEDGE]: defaults.result,
            [RESULTS.INFLUENCE_ON_POLICY]: defaults.result,
            [RESULTS.AWARD]: defaults.result,
            [RESULTS.INVENTION_DISCLOSURE]: defaults.result,
            [RESULTS.PATENTS]: defaults.result,
            [RESULTS.INTELLECTUAL_PROPERTY]: defaults.result,
            [RESULTS.COMPANY]: defaults.result,
        },
    },
    [INITIATIVE.GLOBAL_HEALTH]: {
        name: INITIATIVE.GLOBAL_HEALTH,
        activities: {
            [ACTIVITIES.ENGAGEMENT]: defaults.activity,
            [ACTIVITIES.TEACHING]: defaults.activity,
            [ACTIVITIES.DEVELOPMENT]: defaults.activity,
            [ACTIVITIES.TEAM_EDUCATION]: defaults.activity,
            [ACTIVITIES.SERVICE]: defaults.activity,
        },
        results: {
            [RESULTS.DISSEMINATION]: defaults.result,
            [RESULTS.KNOWLEDGE]: defaults.result,
            [RESULTS.INFLUENCE_ON_POLICY]: defaults.result,
            [RESULTS.AWARD]: defaults.result,
            [RESULTS.PRODUCTS_AND_INVENTIONS]: defaults.result,
        },
    },
    [INITIATIVE.HUMANITARIAN]: {
        name: INITIATIVE.HUMANITARIAN,
        activities: {
            [ACTIVITIES.ENGAGEMENT]: defaults.activity,
            [ACTIVITIES.TEACHING]: defaults.activity,
            [ACTIVITIES.DEVELOPMENT]: defaults.activity,
            [ACTIVITIES.TEAM_EDUCATION]: defaults.activity,
            [ACTIVITIES.SERVICE]: defaults.activity,
            [ACTIVITIES.UPGRADE]: defaults.activity,
        },
        results: {
            [RESULTS.DISSEMINATION]: defaults.result,
            [RESULTS.KNOWLEDGE]: defaults.result,
            [RESULTS.INFLUENCE_ON_POLICY]: defaults.result,
            [RESULTS.INTELLECTUAL_PROPERTY]: defaults.result,
        },
    },
    [INITIATIVE.IMPACT_INVESTMENT]: {
        name: INITIATIVE.IMPACT_INVESTMENT,
        activities: {
            [ACTIVITIES.ENGAGEMENT]: defaults.activity,
            [ACTIVITIES.TEACHING]: defaults.activity,
            [ACTIVITIES.DEVELOPMENT]: defaults.activity,
            [ACTIVITIES.SERVICE]: defaults.activity,
            [ACTIVITIES.UPGRADE]: defaults.activity,
            [ACTIVITIES.INTERVENTION]: defaults.activity,
        },
        results: {
            [RESULTS.DISSEMINATION]: defaults.result,
            [RESULTS.KNOWLEDGE]: defaults.result,
            [RESULTS.INFLUENCE_ON_POLICY]: defaults.result,
            [RESULTS.AWARD]: defaults.result,
            [RESULTS.PRODUCTS_AND_INVENTIONS]: defaults.result,
            [RESULTS.INVENTION_DISCLOSURE]: defaults.result,
            [RESULTS.PATENTS]: defaults.result,
            [RESULTS.INTELLECTUAL_PROPERTY]: defaults.result,
            [RESULTS.COMPANY]: defaults.result,
        },
    },
    [INITIATIVE.INNOVATION]: {
        name: INITIATIVE.INNOVATION,
        activities: {
            [ACTIVITIES.ENGAGEMENT]: defaults.activity,
            [ACTIVITIES.TEACHING]: defaults.activity,
            [ACTIVITIES.DEVELOPMENT]: defaults.activity,
            [ACTIVITIES.SERVICE]: defaults.activity,
            [ACTIVITIES.UPGRADE]: defaults.activity,
            [ACTIVITIES.INTERVENTION]: defaults.activity,
        },
        results: {
            [RESULTS.DISSEMINATION]: defaults.result,
            [RESULTS.KNOWLEDGE]: defaults.result,
            [RESULTS.INFLUENCE_ON_POLICY]: defaults.result,
            [RESULTS.AWARD]: defaults.result,
            [RESULTS.PRODUCTS_AND_INVENTIONS]: defaults.result,
            [RESULTS.INVENTION_DISCLOSURE]: defaults.result,
            [RESULTS.PATENTS]: defaults.result,
            [RESULTS.INTELLECTUAL_PROPERTY]: defaults.result,
            [RESULTS.COMPANY]: defaults.result,
        },
    },
    [INITIATIVE.INTERMEDIATE]: {
        name: INITIATIVE.INTERMEDIATE,
        activities: {
            [ACTIVITIES.ENGAGEMENT]: defaults.activity,
            [ACTIVITIES.TEACHING]: defaults.activity,
            [ACTIVITIES.DEVELOPMENT]: defaults.activity,
            [ACTIVITIES.SERVICE]: defaults.activity,
            [ACTIVITIES.INTERVENTION]: defaults.activity,
        },
        results: {
            [RESULTS.DISSEMINATION]: defaults.result,
            [RESULTS.KNOWLEDGE]: defaults.result,
            [RESULTS.INFLUENCE_ON_POLICY]: defaults.result,
            [RESULTS.AWARD]: defaults.result,
            [RESULTS.PRODUCTS_AND_INVENTIONS]: defaults.result,
            [RESULTS.CUSTOM_OUTPUT]: defaults.result,
        },
    },
    [INITIATIVE.PEP]: {
        name: INITIATIVE.PEP,
        activities: {
            [ACTIVITIES.TEACHING]: {
                ...defaults.activity,
                indicatorMetricTargetOn: true,
            },
            [ACTIVITIES.DEVELOPMENT]: {
                ...defaults.activity,
                indicatorMetricTargetOn: true,
            },
            [ACTIVITIES.ENGAGEMENT]: {
                ...defaults.activity,
                indicatorMetricTargetOn: true,
            },
            [ACTIVITIES.SERVICE]: {
                ...defaults.activity,
                indicatorMetricTargetOn: true,
            },
            [ACTIVITIES.UPGRADE]: {
                ...defaults.activity,
                indicatorMetricTargetOn: true,
            },
        },
        results: {
            [RESULTS.DISSEMINATION]: defaults.result,
            [RESULTS.KNOWLEDGE]: defaults.result,
            [RESULTS.INFLUENCE_ON_POLICY]: defaults.result,
        },
    },
    [INITIATIVE.CSLT]: {
        name: INITIATIVE.CSLT,
        activities: {
            [ACTIVITIES.TEACHING_CAPACITY]: defaults.activity,
            [ACTIVITIES.DEVELOPMENT]: defaults.activity,
            [ACTIVITIES.ENGAGEMENT]: defaults.activity,
            [ACTIVITIES.SERVICE]: defaults.activity,
            [ACTIVITIES.UPGRADE]: defaults.activity,
        },
        results: {
            [RESULTS.DISSEMINATION]: defaults.result,
            [RESULTS.KNOWLEDGE]: defaults.result,
            [RESULTS.INFLUENCE_ON_POLICY]: defaults.result,
            [RESULTS.AWARD]: defaults.result,
        },
    },
    [INITIATIVE.RESEARCH_INFRASTRUCTURE]: {
        name: INITIATIVE.RESEARCH_INFRASTRUCTURE,
        activities: {
            [ACTIVITIES.ENGAGEMENT]: defaults.activity,
            [ACTIVITIES.TEACHING]: defaults.activity,
            [ACTIVITIES.TEAM_EDUCATION]: defaults.activity,
            [ACTIVITIES.PHYSICAL]: defaults.activity,
        },
        results: {
            [RESULTS.RESEARCH]: defaults.result,
            [RESULTS.KNOWLEDGE]: defaults.result,
            [RESULTS.INFLUENCE_ON_POLICY]: defaults.result,
            [RESULTS.AWARD]: defaults.result,
            [RESULTS.INVENTION_DISCLOSURE]: defaults.result,
            [RESULTS.PATENTS]: defaults.result,
            [RESULTS.INTELLECTUAL_PROPERTY]: defaults.result,
            [RESULTS.COMPANY]: defaults.result,
        },
    },
    [INITIATIVE.SCIENCE]: {
        name: INITIATIVE.SCIENCE,
        activities: {
            [ACTIVITIES.ENGAGEMENT]: defaults.activity,
            [ACTIVITIES.TEACHING]: defaults.activity,
            [ACTIVITIES.DEVELOPMENT]: defaults.activity,
        },
        results: {
            [RESULTS.DISSEMINATION]: defaults.result,
            [RESULTS.KNOWLEDGE]: defaults.result,
            [RESULTS.INFLUENCE_ON_POLICY]: defaults.result,
            [RESULTS.AWARD]: defaults.result,
            [RESULTS.PRODUCTS_AND_INVENTIONS]: defaults.result,
            [RESULTS.INVENTION_DISCLOSURE]: defaults.result,
            [RESULTS.PATENTS]: defaults.result,
            [RESULTS.INTELLECTUAL_PROPERTY]: defaults.result,
            [RESULTS.COMPANY]: defaults.result,
        },
    },
    [INITIATIVE.SOCIAL]: {
        name: INITIATIVE.SOCIAL,
        activities: {
            [ACTIVITIES.ENGAGEMENT]: defaults.activity,
            [ACTIVITIES.TEACHING]: defaults.activity,
            [ACTIVITIES.DEVELOPMENT]: defaults.activity,
            [ACTIVITIES.SERVICE]: defaults.activity,
        },
        results: {
            [RESULTS.DISSEMINATION]: defaults.result,
            [RESULTS.KNOWLEDGE]: defaults.result,
            [RESULTS.INFLUENCE_ON_POLICY]: defaults.result,
            [RESULTS.AWARD]: defaults.result,
        },
    },

    // Legacy initiatives
    Advanced: {
        name: 'Advanced',
        activities: {
            [ACTIVITIES.ENGAGEMENT]: defaults.activity,
            [ACTIVITIES.TEACHING]: defaults.activity,
            [ACTIVITIES.DEVELOPMENT]: defaults.activity,
            [ACTIVITIES.SERVICE]: defaults.activity,
        },
        results: {
            [RESULTS.DISSEMINATION]: defaults.result,
            [RESULTS.KNOWLEDGE]: defaults.result,
            [RESULTS.INFLUENCE_ON_POLICY]: defaults.result,
            [RESULTS.AWARD]: defaults.result,
        },
    },
};
