// React
import React from 'react';

// Packages
import t from 'prop-types';
import cc from 'classcat';

const CardContentSectionComponentBox = ({
    children,
    theme,
    className,
    title,
}) => {
    return (
        <div
            className={cc([
                'mb-16',
                'px-16 py-8 border-2 rounded-8',
                className,
                {
                    'border-teal-10': theme === 'teal',
                    'border-blue-10': theme === 'blue',
                },
            ])}>
            {title && (
                <h5
                    className={cc([
                        't-caption-bold',
                        {
                            'text-teal-60': theme === 'teal',
                            'text-blue-60': theme === 'blue',
                        },
                    ])}>
                    {title}
                </h5>
            )}
            {children}
        </div>
    );
};

CardContentSectionComponentBox.propTypes = {
    /* Adds additional class names */
    className: t.string,
    /* Theme */
    theme: t.oneOf(['blue', 'teal']),
};

CardContentSectionComponentBox.defaultProps = {
    theme: 'teal',
    className: '',
};

export default CardContentSectionComponentBox;
