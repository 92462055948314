// React
import React, { useMemo } from 'react';

// Utilities
import { useLabels, useFormat } from 'utilities/hooks';

// Packages
import _groupBy from 'lodash.groupby';

// Components
import EmployeesFundedOverviewPrint from 'components/employeesFundedOverviewPrint';
import SectionContainer from 'components/_report/templates/report_5_0_pdf/sectionContainer';
import Collection from 'components/_report/templates/report_5_0_pdf/collection';
import { ReportUpdate } from 'components/_report/templates/report_5_0_pdf/_cards';
import { PieChartPrint } from 'components/_charts';

// Content cards
import Funding from 'components/_cardContents/funding';
import Collaborator from 'components/_cardContents/collaborator';
import Facility from 'components/_cardContents/facility';
import EmployeesFunded from 'components/_cardContents/employeesFunded';
import Pill from 'components/pill';

const SectionInputsComponent = ({
    utilities,
    items,
    CONSTANTS,
    wrapCardContents = true,
}) => {
    // ///////////////////
    // DATA
    // ///////////////////

    // Based on baseUrl in config
    const sectionComponents = {
        funding(props) {
            return <FundingSection {...props} />;
        },
        collaborators(props) {
            return <CollaboratorsSection {...props} />;
        },
        facilities(props) {
            return <FacilitiesSection {...props} />;
        },
        'employees-funded'(props) {
            return <EmployeesFundedSection {...props} />;
        },
    };

    // ///////////////////
    // RENDER
    // ///////////////////

    return items.map(navigationItem => (
        <React.Fragment key={navigationItem?.baseUrl}>
            {sectionComponents[navigationItem.baseUrl] &&
                sectionComponents[navigationItem.baseUrl]({
                    utilities,
                    CONSTANTS,
                    navigationItem,
                    wrapCardContents,
                })}
        </React.Fragment>
    ));
};

const FundingSection = ({
    utilities,
    CONSTANTS,
    navigationItem,
    wrapCardContents,
}) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { label, getValueLabel, object } = useLabels();
    const format = useFormat();

    // ///////////////////
    // DATA
    // ///////////////////

    const items = utilities.fundings.getAll();

    const fundingData = useMemo(
        () =>
            Object.entries(_groupBy(items, x => x.CurrencyIsoCode)).reduce(
                (acc, [currency, items]) => {
                    return {
                        ...acc,
                        [currency]: items.map(item => ({
                            ...item,
                            value: item.Amount_Funded__c,
                        })),
                    };
                },
                {}
            ),
        [items]
    );

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <SectionContainer
            {...{
                item: navigationItem,
            }}>
            {fundingData &&
                Object.entries(fundingData).length > 0 &&
                Object.entries(fundingData).map(([currency, data]) => (
                    <div className="mb-32">
                        <PieChartPrint
                            key={currency}
                            {...{
                                heading: currency,
                                data,
                                size: 'fit',
                                legend: {
                                    title: label(
                                        'InitiativeViewFundingOverview'
                                    ),
                                    key(item) {
                                        return item?.Id;
                                    },
                                    label(item) {
                                        return (
                                            <div className="flex flex-col">
                                                <span>
                                                    {format.funderNames(
                                                        utilities.funders.getFromFundingId(
                                                            item.Id
                                                        )
                                                    )}
                                                </span>
                                                <span className="t-footnote">
                                                    {format.currency(
                                                        item.value,
                                                        currency
                                                    )}
                                                </span>
                                            </div>
                                        );
                                    },
                                },
                                annotation: {
                                    chartTitle: `${label(
                                        'InitiativeViewTotalFunded'
                                    )} (${currency})`,
                                    chartDataLabel(totalValue) {
                                        return format.currency(totalValue);
                                    },
                                },
                            }}
                        />
                    </div>
                ))}
            <Collection
                {...{
                    collection: {
                        items,
                        emptyLabel: label('EmptyStateWizardPageFunding'),
                    },
                    card: {
                        preTitle(item) {
                            return (
                                <>
                                    {getValueLabel(
                                        'Initiative_Funding__c.Funding_Type__c',
                                        item?.Funding_Type__c
                                    )}
                                    {item.Initiator_Funding__c ? (
                                        <Pill className="ml-8" theme="blue">
                                            {object.label(
                                                'Initiative_Funder__c.Initiator_Funding__c'
                                            )}
                                        </Pill>
                                    ) : null}
                                </>
                            );
                        },
                        title(item) {
                            return `${
                                item.Funding_Title__c
                                    ? `${item.Funding_Title__c} - `
                                    : ''
                            }${format.funderNames(
                                utilities.funders.getFromFundingId(item.Id)
                            )}`;
                        },
                        postTitle(item) {
                            return format.currency(
                                item?.Amount_Funded__c,
                                item?.CurrencyIsoCode
                            );
                        },
                        components(item) {
                            return {
                                cardContent: (
                                    <Funding
                                        {...{
                                            item,
                                            theme: 'blue',
                                            utilities,
                                            wrap: wrapCardContents,
                                        }}
                                    />
                                ),
                                reportUpdate(additinalProps) {
                                    return (
                                        <ReportUpdate
                                            {...{
                                                ...additinalProps,
                                                utilities,
                                                tagging: {
                                                    item,
                                                    relationKey:
                                                        'Initiative_Funding__c',
                                                    types: [
                                                        CONSTANTS.TAGS.FUNDING,
                                                    ],
                                                },
                                                reflection: {
                                                    item,
                                                    relationKey:
                                                        'Initiative_Funding__c',
                                                    type:
                                                        CONSTANTS.REPORT_DETAILS
                                                            .FUNDING,
                                                },
                                            }}
                                        />
                                    );
                                },
                            };
                        },
                    },
                }}
            />
        </SectionContainer>
    );
};

const CollaboratorsSection = ({
    utilities,
    CONSTANTS,
    navigationItem,
    wrapCardContents,
}) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { label, getValueLabel } = useLabels();

    // ///////////////////
    // DATA
    // ///////////////////

    const items = utilities.collaborators
        .getAllRelevant()
        .sort((a, b) => a?.Type__c?.localeCompare(b?.Type__c));

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <SectionContainer
            {...{
                item: navigationItem,
            }}>
            <Collection
                {...{
                    collection: {
                        items,
                        emptyLabel: label('EmptyStateWizardPageCollaborators'),
                    },
                    card: {
                        preTitle(item) {
                            return getValueLabel(
                                'Initiative_Collaborator__c.Type__c',
                                item?.Type__c
                            );
                        },
                        title(item) {
                            return item?.Account__r?.Name;
                        },
                        components(item) {
                            return {
                                cardContent:
                                    item.Type__c !==
                                    CONSTANTS.COLLABORATORS
                                        .FINANCIAL_RECIPIENTS ? (
                                        <Collaborator
                                            {...{
                                                item,
                                                theme: 'blue',
                                                wrap: wrapCardContents,
                                            }}
                                        />
                                    ) : null,
                                reportUpdate(additinalProps) {
                                    return (
                                        <ReportUpdate
                                            {...{
                                                ...additinalProps,
                                                utilities,
                                                reflection: {
                                                    item,
                                                    relationKey:
                                                        'Initiative_Collaborator__c',
                                                    type:
                                                        CONSTANTS.REPORT_DETAILS
                                                            .COLLABORATOR_OVERVIEW,
                                                },
                                            }}
                                        />
                                    );
                                },
                            };
                        },
                    },
                }}
            />
        </SectionContainer>
    );
};

const FacilitiesSection = ({
    utilities,
    CONSTANTS,
    navigationItem,
    wrapCardContents,
}) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { label, getValueLabel } = useLabels();

    // ///////////////////
    // DATA
    // ///////////////////

    const items = utilities.locations.getAll();

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <SectionContainer
            {...{
                item: navigationItem,
            }}>
            <Collection
                {...{
                    collection: {
                        items,
                        emptyLabel: label('EmptyStateWizardPageFacilities'),
                    },
                    card: {
                        preTitle(item) {
                            return getValueLabel(
                                'Account_Location__c.Type__c',
                                item?.Initiative_Location__r?.Type__c
                            );
                        },
                        title(item) {
                            return item?.Initiative_Location__r?.Name;
                        },
                        components(item) {
                            return {
                                cardContent: (
                                    <Facility
                                        {...{
                                            item,
                                            theme: 'blue',
                                            wrap: wrapCardContents,
                                        }}
                                    />
                                ),
                                reportUpdate(additinalProps) {
                                    return (
                                        <ReportUpdate
                                            {...{
                                                ...additinalProps,
                                                utilities,
                                                reflection: {
                                                    item,
                                                    relationKey:
                                                        'Initiative_Location__c',
                                                    type:
                                                        CONSTANTS.REPORT_DETAILS
                                                            .LOCATION,
                                                },
                                            }}
                                        />
                                    );
                                },
                            };
                        },
                    },
                }}
            />
        </SectionContainer>
    );
};

const EmployeesFundedSection = ({
    utilities,
    CONSTANTS,
    navigationItem,
    wrapCardContents,
}) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { label } = useLabels();

    // ///////////////////
    // DATA
    // ///////////////////

    const items = utilities.employeesFunded.getAll();

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <SectionContainer
            {...{
                item: navigationItem,
            }}>
            <div className="mb-32">
                <EmployeesFundedOverviewPrint {...{ employees: items }} />
            </div>
            <Collection
                {...{
                    collection: {
                        items,
                        emptyLabel: label(
                            'EmptyStateWizardPageEmployeesFunded'
                        ),
                    },
                    card: {
                        preTitle(item) {
                            return item?.Full_Name__c;
                        },
                        title(item) {
                            return (
                                item?.Job_Title__c || item?.Academic_Title__c
                            );
                        },
                        components(item) {
                            return {
                                cardContent: (
                                    <EmployeesFunded
                                        {...{
                                            item,
                                            theme: 'blue',
                                            utilities,
                                            wrap: wrapCardContents,
                                        }}
                                    />
                                ),
                                reportUpdate(additinalProps) {
                                    return (
                                        <ReportUpdate
                                            {...{
                                                ...additinalProps,
                                                utilities,
                                                tagging: {
                                                    item,
                                                    relationKey:
                                                        'Initiative_Employee_Funded__c',
                                                    types: [
                                                        CONSTANTS.TAGS
                                                            .EMPLOYEE_FUNDED,
                                                    ],
                                                },
                                                reflection: {
                                                    item,
                                                    relationKey:
                                                        'Initiative_Employee_Funded__c',
                                                    type:
                                                        CONSTANTS.REPORT_DETAILS
                                                            .EMPLOYEES_FUNDED_OVERVIEW,
                                                },
                                            }}
                                        />
                                    );
                                },
                            };
                        },
                    },
                }}
            />
        </SectionContainer>
    );
};

SectionInputsComponent.propTypes = {};

SectionInputsComponent.defaultProps = {};

export default SectionInputsComponent;
