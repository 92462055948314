// Packages
import { useState, useEffect } from 'react';

// Data
import { fgConfig } from 'utilities/configuration';

const useConfig = ({
    initiativeType = null,
    activityType = null,
    resultType = null,
}) => {
    // ///////////////////
    // METHODS
    // ///////////////////

    function getActivities() {
        if (!initiativeType) {
            console.error('Config.getActivities: Initiative not set');
            return [];
        }
        return fgConfig[initiativeType]?.activities;
    }

    function getActivityTypes() {
        return Object.keys(getActivities());
    }

    function getResults() {
        if (!initiativeType) {
            console.error('Config.getResults: Initiative not set');
            return [];
        }
        return fgConfig[initiativeType]?.results;
    }

    function getResultTypes() {
        return Object.keys(getResults());
    }

    function indicatorMetricTargetOn() {
        if (!initiativeType || !activityType) {
            console.error('Config: Initiative or activity not set');
            return false;
        }
        return getActivities()?.[activityType]?.indicatorMetricTargetOn;
    }

    // ///////////////////
    // EFFECTS
    // ///////////////////

    return {
        getActivities,
        getActivityTypes,
        getResults,
        getResultTypes,
        indicatorMetricTargetOn,
    };
};

export default useConfig;
