// React
import React from 'react';

// Packages
import t from 'prop-types';

// Utilities
import { useLabels } from 'utilities/hooks';

// Components
import Card from 'components/_cardContentElements';

const EvaluationEvaluationComponent = ({
    item,
    theme = 'teal',
    wrap = false,
}) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { getValueLabel, object } = useLabels();

    // ///////////////////
    // DATA
    // ///////////////////

    const { Evaluation_Method__c, Description__c } = item;

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <Card.CardContentWrapper
            {...{
                wrap,
            }}>
            {/* Evaluation type */}
            {Evaluation_Method__c && (
                <Card.CardContentSection
                    {...{
                        theme,
                        wrap,
                        title: object.label(
                            'Initiative_Report_Detail__c.Evaluation_Method__c'
                        ),
                    }}>
                    <p>
                        {getValueLabel(
                            'Initiative_Report_Detail__c.Evaluation_Method__c',
                            Evaluation_Method__c
                        )}
                    </p>
                </Card.CardContentSection>
            )}

            {/* Description */}
            {Description__c && (
                <Card.CardContentSection
                    {...{
                        theme,
                        wrap,
                    }}>
                    <p>{Description__c}</p>
                </Card.CardContentSection>
            )}
        </Card.CardContentWrapper>
    );
};

EvaluationEvaluationComponent.propTypes = {
    item: t.object,
};

EvaluationEvaluationComponent.defaultProps = {};

export default EvaluationEvaluationComponent;
