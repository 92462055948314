// React
import React from 'react';

// Packages
import cc from 'classcat';
import t from 'prop-types';

// Utilities
import { useLabels, useElseware } from 'utilities/hooks';

// Components
import Card from 'components/_cardContentElements';
import Pill from 'components/pill';

const FacilityComponent = ({ item, theme = 'teal', wrap = false }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { object, getValueLabel } = useLabels();
    const { ewGet } = useElseware();

    // ///////////////////
    // DATA
    // ///////////////////

    const { Initiative_Location__c } = item;
    const { data: currentLocationData } = ewGet(
        'account-location/account-location',
        {
            id: Initiative_Location__c,
        }
    );
    const currentLocation = currentLocationData?.data ?? {};
    const {
        Type__c,
        Organisation__r,
        Address__c,
        Description__c,
    } = currentLocation;

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <Card.CardContentWrapper
            {...{
                wrap,
            }}>
            {/* Provider */}
            <Card.CardContentSection
                {...{
                    theme,
                    wrap,
                    title: object.label('Account_Location__c.Organisation__c'),
                }}>
                <p>{Organisation__r?.Name}</p>
            </Card.CardContentSection>

            {/* Type */}
            {Type__c && (
                <Card.CardContentSection
                    {...{
                        theme,
                        wrap,
                        title: object.label('Account_Location__c.Type__c'),
                    }}>
                    <Pill theme={theme}>
                        {getValueLabel('Account_Location__c.Type__c', Type__c)}
                    </Pill>
                </Card.CardContentSection>
            )}

            {/* Description */}
            {Description__c && (
                <Card.CardContentSection
                    {...{
                        theme,
                        wrap,
                    }}>
                    <p>{Description__c}</p>
                </Card.CardContentSection>
            )}

            {/* Address */}
            {[
                Address__c?.Address__Street__s,
                Address__c?.Address__PostalCode__s,
                Address__c?.Address__City__s,
                Address__c?.Address__CountryCode__s,
            ].some(x => x) && (
                <Card.CardContentSection
                    {...{
                        theme,
                        wrap,
                        title: object.label('Account_Location__c.Address__c'),
                    }}>
                    <div
                        className={cc([
                            'flex flex-col p-16 space-y-8 mt-4 t-sh7 rounded-8',
                            {
                                'text-teal-100 bg-teal-10': theme === 'teal',
                                'text-blue-100 bg-blue-10': theme === 'blue',
                            },
                        ])}>
                        <p>{Address__c?.Address__Street__s}</p>
                        <p>{Address__c?.Address__PostalCode__s}</p>
                        <p>{Address__c?.Address__City__s}</p>
                        <p>{Address__c?.Address__CountryCode__s}</p>
                    </div>
                </Card.CardContentSection>
            )}
        </Card.CardContentWrapper>
    );
};

FacilityComponent.propTypes = {
    item: t.object,
};

FacilityComponent.defaultProps = {};

export default FacilityComponent;
