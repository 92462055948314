// React
import React from 'react';

// Packages

// Utilities
import { useLabels } from 'utilities/hooks';

// Components

const SectionContainerComponent = ({ children, item }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { label } = useLabels();

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div
            className="pt-32 mt-32 border-t border-dashed border-teal-120 report-split"
            style={{
                pageBreakBefore: 'always',
            }}>
            <h4 className="flex flex-col mb-32">
                <div className="flex flex-col order-2 mt-12 mr-24">
                    <span className="t-h2">{label(item?.title)}</span>
                    <span className="t-caption">
                        {label(`${item?.title}Strapline`)}
                    </span>
                </div>
            </h4>
            <div className="w-full h-1 mb-48 bg-blue-20"></div>
            {children}
        </div>
    );
};

SectionContainerComponent.propTypes = {};

SectionContainerComponent.defaultProps = {};

export default SectionContainerComponent;
