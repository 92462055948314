// React
import React, { useEffect, useState, useRef } from 'react';
import { createPortal } from 'react-dom';

// Packages
import t from 'prop-types';
import { Transition } from '@headlessui/react';

// Utilities

// Components

const BaseModalComponent = ({ isOpen, close, children }) => {
    // ///////////////////
    // STATE
    // ///////////////////

    const [showContent, setShowContent] = useState(false);

    // ///////////////////
    // REFS
    // ///////////////////

    const modalRef = useRef(null);

    // ///////////////////
    // METHODS
    // ///////////////////

    // Function: Event wrapper for closing outside click
    function handleClick(event) {
        if (
            isOpen &&
            modalRef.current &&
            !modalRef.current.contains(event.target)
        ) {
            // Disable until better cancel solution
            // close();
        }
    }

    // ///////////////////
    // EFFECTS
    // ///////////////////

    // Effect: Delay content fade in
    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                setShowContent(true);
            }, 200);
        } else {
            setShowContent(false);
        }
    }, [isOpen]);

    // Effect: Catch outside clicks and close
    useEffect(() => {
        if (isOpen) {
            document.addEventListener('click', handleClick, true);
            return () => {
                document.removeEventListener('click', handleClick, true);
            };
        }
    }, [isOpen]);

    // ///////////////////
    // RENDER
    // ///////////////////

    return createPortal(
        <Transition
            show={isOpen}
            enter="transition-medium"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-default"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="fixed inset-0 flex justify-center bg-opacity-25 bg-blue-120 z-modal">
            <Transition
                show={showContent}
                enter="transition-medium"
                enterFrom="opacity-0 translate-y-10"
                enterTo="opacity-100 translate-y-0"
                leave="transition-default"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className="absolute inset-0 flex items-end justify-center sm:items-center">
                <div
                    ref={modalRef}
                    className="flex flex-col w-full max-h-[90%] max-w-[664px] p-18 lg:p-[30px] bg-white rounded-t-16 sm:rounded-16 sm:mx-16">
                    {children}
                </div>
            </Transition>
            <style global jsx>{`
                html {
                    overflow-y: hidden;
                }
            `}</style>
        </Transition>,
        document.getElementById('modal')
    );
};

BaseModalComponent.propTypes = {
    isOpen: t.bool,
    close: t.func.isRequired,
};

BaseModalComponent.defaultProps = {
    isOpen: false,
    close: null,
};

export default BaseModalComponent;
