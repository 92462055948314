// React
import React from 'react';

// Packages
import cc from 'classcat';
import t from 'prop-types';

// Utilities

// Components
import Button from 'components/button';
import ActiveLink from 'components/activeLink';
import FullWidthWrapper from 'components/_layout/fullWidthWrapper';

// Icons
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

const PageTop = ({
    heading,
    methods,
    tabs,
    tabButtons,
    fullWidth,
    children,
}) => {
    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div
            className={cc([
                'flex flex-col',
                {
                    'mb-36': tabs.length === 0,
                    'mb-16': tabs.length > 0,
                },
            ])}>
            <FullWidthWrapper>
                <div
                    className={cc([
                        'flex justify-between relative',
                        { 'max-w-[900px]': !fullWidth },
                    ])}>
                    <h2 className="t-h1">{heading} </h2>

                    {/* Controls */}
                    <div className="absolute right-0 flex items-center space-x-12 translate-y-[-120%] md:translate-y-0 3xl:md:translate-y-[20%]">
                        {children}
                        {methods?.secondary?.action && (
                            <Button
                                variant="tertiary"
                                theme="blue"
                                action={methods.secondary.action}>
                                {methods.secondary.label}
                            </Button>
                        )}
                        {methods?.primary?.action && (
                            <Button
                                theme="blue"
                                action={methods.primary.action}>
                                {methods.primary.label}
                            </Button>
                        )}
                        {methods?.left?.action && (
                            <Button
                                variant="secondary"
                                theme="blue"
                                disabled={methods.left.disabled}
                                icon={FiChevronLeft}
                                iconPosition="center"
                                iconType="stroke"
                                className="!px-8"
                                action={methods.left.action}
                            />
                        )}
                        {methods?.right?.action && (
                            <Button
                                variant="secondary"
                                theme="blue"
                                disabled={methods.right.disabled}
                                icon={FiChevronRight}
                                iconPosition="center"
                                iconType="stroke"
                                className="!px-8"
                                action={methods.right.action}
                            />
                        )}
                    </div>
                </div>
            </FullWidthWrapper>

            {/* Tab navigation */}
            {(tabs.length > 0 || tabButtons.length > 0) && (
                <div className="flex flex-wrap pt-20">
                    {tabs.length > 1 &&
                        tabs.map((tab, index) => (
                            <ActiveLink
                                key={tab.href + index}
                                {...{
                                    href: tab.href,
                                    active: '!text-blue-100 !border-blue-100',
                                }}>
                                <a className="pb-4 mb-16 mr-24 text-blue-300 border-b-2 border-transparent whitespace-nowrap hover:border-blue-300 t-h6 transition-default">
                                    {tab.label}
                                </a>
                            </ActiveLink>
                        ))}

                    {tabButtons.length > 1 &&
                        tabButtons.map((tab, index) => (
                            <button
                                key={index}
                                onClick={tab.action}
                                className={cc([
                                    'pb-4 mb-16 mr-24 text-blue-300 border-b-2 border-transparent whitespace-nowrap hover:border-blue-300 t-h6 transition-default',
                                    {
                                        '!text-blue-100 !border-blue-100':
                                            tab.active,
                                    },
                                ])}>
                                {tab.label}
                            </button>
                        ))}
                </div>
            )}
        </div>
    );
};

PageTop.propTypes = {
    heading: t.string.isRequired,
    fullWidth: t.bool,
    methods: t.shape({
        primary: t.shape({
            action: t.oneOfType([t.string, t.func]),
            label: t.string.isRequired,
        }),
        secondary: t.shape({
            action: t.oneOfType([t.string, t.func]),
            label: t.string.isRequired,
        }),
        left: t.shape({
            action: t.func,
            disabled: t.bool,
        }),
        right: t.shape({
            action: t.func,
            disabled: t.bool,
        }),
    }),
    tabs: t.arrayOf(
        t.shape({
            label: t.string.isRequired,
            href: t.string.isRequired,
        })
    ),
    tabButtons: t.arrayOf(
        t.shape({
            label: t.string.isRequired,
            action: t.oneOfType([t.string, t.func]).isRequired,
            active: t.bool.isRequired,
        })
    ),
};

PageTop.defaultProps = {
    heading: '',
    fullWidth: false,
    methods: null,
    tabs: [],
    tabButtons: [],
};

export default PageTop;
