import CardContentDates from './cardContentDates';
import { CardContentPairBox, CardContentPair } from './cardContentPairBox';
import CardContentSection from './cardContentSection';
import CardContentSectionBox from './cardContentSectionBox';
import CardContentWrapper from './cardContentWrapper';

export default {
    CardContentDates,
    CardContentPairBox,
    CardContentPair,
    CardContentSection,
    CardContentSectionBox,
    CardContentWrapper,
};
