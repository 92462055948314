// React
import React from 'react';

// Packages

// Utilities
import { useLabels, useTagging } from 'utilities/hooks';

// Components
import Card from 'components/_cardContentElements';
import Tags from 'components/tags';

const FundingComponent = ({
    item,
    theme = 'teal',
    utilities,
    wrap = false,
}) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { object, getValueLabel, label } = useLabels();
    const tagging = useTagging({
        relationKey: 'Initiative_Funding__c',
    });

    // ///////////////////
    // DATA
    // ///////////////////

    const leadFunders = utilities.funders.getTypeLeadFromFundingId(item.Id);
    const addFunders = utilities.funders.getTypeAdditionalFromFundingId(
        item.Id
    );

    const mainApplicant = utilities.fundingRecipients.getTypeMainApplicantFromFundingId(
        item.Id
    );
    const coApplicants = utilities.fundingRecipients.getTypeCoApplicantsFromFundingId(
        item.Id
    );

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <Card.CardContentWrapper
            {...{
                wrap,
            }}>
            {/* Funders */}
            <Card.CardContentSection
                {...{
                    wrap,
                    theme,
                    title: object.label(
                        'Initiative_Funder__c.Account__c__Lead'
                    ),
                }}>
                <ul>
                    {leadFunders.map(funder => (
                        <li key={funder.Id}>- {funder?.Account__r?.Name}</li>
                    ))}
                </ul>
            </Card.CardContentSection>

            {/* Funders */}
            {addFunders.length > 0 && (
                <Card.CardContentSection
                    {...{
                        wrap,
                        theme,
                        title: object.label(
                            'Initiative_Funder__c.Account__c__Co'
                        ),
                    }}>
                    <ul>
                        {addFunders.map(funder => (
                            <li key={funder.Id}>
                                - {funder?.Account__r?.Name}
                            </li>
                        ))}
                    </ul>
                </Card.CardContentSection>
            )}
            {/* Grant ID */}
            <Card.CardContentSection
                {...{
                    wrap,
                    theme,
                    title: object.label(
                        'Initiative_Funding__c.Application_Id__c'
                    ),
                }}>
                <p>{item?.Application_Id__c}</p>
            </Card.CardContentSection>
            {/* Main applicant */}
            <Card.CardContentSection
                {...{
                    wrap,
                    theme,
                    title: object.label(
                        'Initiative_Collaborator__c.Account__c__Main'
                    ),
                }}>
                <ul>
                    <li>- {mainApplicant?.Account__r?.Name}</li>
                </ul>
            </Card.CardContentSection>
            {/* Co applicants */}
            {coApplicants.length > 0 && (
                <Card.CardContentSection
                    {...{
                        wrap,
                        theme,
                        title: object.label(
                            'Initiative_Collaborator__c.Account__c__Additional'
                        ),
                    }}>
                    <ul>
                        {coApplicants.map(applicant => (
                            <li key={applicant.Id}>
                                - {applicant?.Account__r?.Name}
                            </li>
                        ))}
                    </ul>
                </Card.CardContentSection>
            )}
            {/* Start date / End date  */}
            <Card.CardContentDates
                {...{
                    theme,
                    startDate: item?.Grant_Start_Date__c,
                    startDateTitle: object.label(
                        'Initiative_Funding__c.Grant_Start_Date__c'
                    ),
                    endDate: item?.Grant_End_Date__c,
                    endDateTitle: object.label(
                        'Initiative_Funding__c.Grant_End_Date__c'
                    ),
                }}
            />
            {/* Tags */}
            {tagging?.getCurrentTags(item).length > 0 && (
                <Card.CardContentSection
                    {...{
                        theme,
                        title: label('DetailsTagsFunding'),
                    }}>
                    <Tags
                        {...{
                            theme,
                            tags: tagging?.getCurrentTags(item),
                            className: 'mt-4',
                        }}
                    />
                </Card.CardContentSection>
            )}
        </Card.CardContentWrapper>
    );
};

FundingComponent.propTypes = {};

FundingComponent.defaultProps = {};

export default FundingComponent;
