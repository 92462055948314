// React
import { useEffect, useMemo, useState } from 'react';

// Packages
import _uniqBy from 'lodash.uniqby';
import _groupBy from 'lodash.groupby';

// Utilities
import { sortTags } from 'utilities';
import { useInitiativeDataStore, useDefaultTagsStore } from 'utilities/store';
import { useElseware, useLabels, useContext } from 'utilities/hooks';

const useTagging = ({
    labelKey,
    relationKey,
    itemKey = 'Id',
    types = [],
    identifier = 'default',
    fallbackToDefaultTags = false,
}) => {
    // ///////////////////
    // STORES
    // ///////////////////

    const { getDefaultCollection, getDefaultTags } = useDefaultTagsStore();
    const { utilities, CONSTANTS } = useInitiativeDataStore();
    const { CONTEXT, CONTEXTS, REPORT_ID } = useContext();

    // ///////////////////
    // VARIABLES
    // ///////////////////

    const taggingFunders =
        CONTEXT === CONTEXTS.REPORT
            ? utilities.funders.getFromFundingId(
                  utilities.reports.get(REPORT_ID)?.Funding_Report__c
              )
            : utilities.funders.getAll();

    // ///////////////////
    // HOOKS
    // ///////////////////

    const { tagLabel, label } = useLabels();
    const { ewCreate, ewDelete } = useElseware();

    // ///////////////////
    // STATE
    // ///////////////////

    const [tagCollections, setTagCollections] = useState([]);
    const [done, setDone] = useState(false);

    // ///////////////////
    // DATA
    // ///////////////////

    const metricObjects = {
        primary: {
            label: label('ActivityBuilderMetric_PrimaryAudience_Label'),
            subLabel: label('ActivityBuilderMetric_PrimaryAudience_SubLabel'),
            required: true,
        },
        secondary: {
            label: label('ActivityBuilderMetric_SecondaryAudience_Label'),
            subLabel: label('ActivityBuilderMetric_SecondaryAudience_SubLabel'),
            required: true,
        },
        additional: {
            label: label('ActivityBuilderMetric_AdditionalMetric_Label'),
            subLabel: label('ActivityBuilderMetric_AdditionalMetric_SubLabel'),
            required: true,
        },
    };

    // ///////////////////
    // METHODS
    // ///////////////////

    function _getTagCollections() {
        let collections = _getAllCollections();

        let tagCollectionsArray = [];
        for (const c of collections) {
            const _options = _getTagOptions(c, types);
            if (_options.length > 0) {
                tagCollectionsArray = [
                    ...tagCollectionsArray,
                    { ...c, _options },
                ];
            }
        }

        return tagCollectionsArray;
    }

    function _getAllCollections() {
        const funderCollections = _uniqBy(taggingFunders, x => x.Account__c)
            .map(x => x.Account__c)
            .map(id =>
                utilities.tags.getTypeFromFunderId(
                    CONSTANTS.TAGS.COLLECTION,
                    id
                )
            )
            .flat();

        return funderCollections.length === 0 && fallbackToDefaultTags
            ? getDefaultCollection()
            : funderCollections;
    }

    function _getAllTags(collection) {
        const getter = collection.DefaultCollection
            ? getDefaultTags
            : utilities.funderTags.getTypeFromCollectionId;
        return types
            .map(tagType => getter(tagType, collection?.Tag__r?.Id))
            .flat();
    }

    function _getTagOptions(collection) {
        // Get all tags
        const allTags = _getAllTags(collection);

        // Get default tags (no category)
        const tagOptionsWithoutCategory = allTags.filter(
            tag => !tag.Category__c
        );

        // Get tags based on initiative category
        const tagOptionsWithInitiativeCategory = allTags.filter(
            tag => tag.Category__c === utilities.initiative.get()?.Category__c
        );

        const sortedTags = sortTags([
            ...tagOptionsWithoutCategory,
            ...tagOptionsWithInitiativeCategory,
        ]);

        // Return both sets of tags
        return sortedTags.map(tag => ({
            label: tagLabel(tag),
            value: tag.Id,
        }));
    }

    function _getField(collection, index) {
        return {
            type: 'TagsList',
            name: `taggingSelect-${identifier}-${collection.Tag__c}`,
            object: {
                label:
                    index === 0
                        ? `${collection.Account_Name__c} ${label(
                              `TaggingLabel${labelKey}`
                          )}`
                        : null,
                subLabel:
                    index === 0 ? label(`TaggingSubLabel${labelKey}`) : null,
            },
            sort: false,
            selectLabel: tagLabel(collection.Tag__r),
            listMaxLength: 20,
            async options() {
                return collection?._options ?? [];
            },
        };
    }

    function _getMetricField(collection, index, showLabels) {
        return {
            type: 'TagsList',
            name: `taggingSelect-${identifier}-${collection.Tag__c}`,
            object: {
                label: showLabels ? metricObjects[identifier]?.label : null,
                subLabel: showLabels
                    ? metricObjects[identifier]?.subLabel
                    : null,
                required: metricObjects[identifier]?.required,
            },
            sort: false,
            selectLabel:
                !collection.DefaultCollection &&
                `${collection?.Account_Name__c} ${label('AudienceTagHeading')}`,
            listMaxLength: 1,
            async options() {
                return collection?._options ?? [];
            },
        };
    }

    function _getOwnTags(item) {
        return utilities.tags
            .getFromRelationKeyId(relationKey, item[itemKey])
            .filter(tag => tag.Tag_Type__c !== CONSTANTS.TAGS.COLLECTION)
            .filter(tag =>
                types.length > 0 ? types.includes(tag.Tag_Type__c) : true
            )
            .filter(tag =>
                _uniqBy(taggingFunders, x => x.Account__c)
                    .map(x => x.Account__c)
                    .includes(tag?.Tag__r?.Account__c)
            );
    }

    function _getDefaultTags(item) {
        return utilities.tags
            .getFromRelationKeyId(relationKey, item[itemKey])
            .filter(tag => tag.Tag_Type__c !== CONSTANTS.TAGS.COLLECTION)
            .filter(tag =>
                types.length > 0 ? types.includes(tag.Tag_Type__c) : true
            );
    }

    function hasOwnTags(item) {
        return _getOwnTags(item).length > 0;
    }

    function getCurrentTags(item) {
        return hasOwnTags(item) ? _getOwnTags(item) : _getDefaultTags(item);
    }

    function getTagsFromFieldValue(item) {
        let tagsFromFieldValue = [];
        for (const [fieldName, collection] of Object.entries(fieldsData)) {
            const allTags = _getAllTags(collection);
            if (item[fieldName]) {
                const tags = item[fieldName].map(value =>
                    allTags.find(t => t.Id === value)
                );
                if (tags.length > 0) {
                    tagsFromFieldValue = [
                        ...tagsFromFieldValue,
                        {
                            fieldName,
                            collection,
                            tags,
                        },
                    ];
                }
            }
        }
        return tagsFromFieldValue;
    }

    function setValues(form, item) {
        const currentTags = getCurrentTags(item);

        for (const c of tagCollections) {
            form.setValue(
                `taggingSelect-${identifier}-${c.Tag__c}`,
                currentTags
                    .filter(tag => tag.Tag__r?.Collection__c === c.Tag__c)
                    .map(tag => tag.Tag__r?.Id)
            );
        }
    }

    async function submit(formData, item) {
        // Collect tagging data based on selects
        let tags = [];
        let tagsToRemove = [];
        for (const c of _getTagCollections()) {
            // Get selectLists from formData from dynamic naming
            const taggingSelect =
                formData[`taggingSelect-${identifier}-${c.Tag__c}`];
            const taggingSelectRemoved =
                formData[`taggingSelect-${identifier}-${c.Tag__c}_Removed`];

            // Add tags to tagsData from each of the selectLists
            for (const tag of taggingSelect) {
                tags.push({
                    [relationKey]: item[itemKey],
                    Tag__c: tag,
                });
            }

            for (const tag of taggingSelectRemoved) {
                tagsToRemove.push(tag);
            }
        }

        // Bulk update (create/delete) initiative tags (POST)
        const { addedTags, removedTags } = await ewCreate(
            'initiative-tag/initiative-tags-bulk',
            {
                Initiative__c: utilities.initiative.get().Id,
                tags,
                tagsToRemove,
                relationKey,
                parentId: item[itemKey],
            }
        );

        // Delete from store
        removedTags.forEach(tagId =>
            utilities.removeInitiativeData('_tags', tagId)
        );

        // Update tags in initiative with new tags
        Object.values(addedTags).forEach(tagData =>
            utilities.updateInitiativeData('_tags', tagData)
        );
    }

    async function remove(item) {
        const currentTags = getCurrentTags(item);
        if (currentTags.length > 0) {
            await Promise.all(
                currentTags.map(tag => {
                    // Delete from server
                    ewDelete('initiative-tag/initiative-tag', tag.Id);
                    // Delete from store
                    utilities.removeInitiativeData('_tags', tag.Id);
                })
            );
        }
    }

    // ///////////////////
    // EFFECTS
    // ///////////////////

    useEffect(() => {
        if (!done && utilities.funderTags.isReady()) {
            const tagCollections = _getTagCollections();
            if (utilities.funderTags.getAll().length > 0) {
                setTagCollections(tagCollections);
                setDone(true);
            }
        }
    }, [utilities.funderTags.isReady(), types]);

    // ///////////////////
    // MEMO
    // ///////////////////

    const fields = useMemo(() => {
        const grouped = _groupBy(tagCollections, x => x.Account_Name__c);
        let fields = [];
        for (const [, collections] of Object.entries(grouped)) {
            fields = [
                ...fields,
                ...collections.map((c, index) => _getField(c, index)),
            ];
        }
        return fields;
    }, [tagCollections]);

    const metricFields = useMemo(() => {
        const grouped = _groupBy(tagCollections, x => x.Account_Name__c);
        let fields = [];
        for (const [, collections] of Object.entries(grouped)) {
            const showLabels = fields.length === 0;
            fields = [
                ...fields,
                ...collections.map((c, index) =>
                    _getMetricField(c, index, showLabels)
                ),
            ];
        }
        return fields;
    }, [tagCollections]);

    const fieldsData = useMemo(() => {
        return tagCollections.reduce(
            (acc, c) => ({
                ...acc,
                [`taggingSelect-${identifier}-${c.Tag__c}`]: c,
            }),
            {}
        );
    }, [tagCollections]);

    const formFields = useMemo(() => {
        return tagCollections.reduce(
            (acc, c) => ({
                ...acc,
                [c.Tag__c]: `taggingSelect-${identifier}-${c.Tag__c}`,
            }),
            {}
        );
    }, [tagCollections]);

    // ///////////////////
    // RETURN
    // ///////////////////

    return {
        hasOwnTags,
        getCurrentTags,
        getTagsFromFieldValue,
        setValues,
        fields,
        fieldsData,
        formFields,
        metricFields,
        submit,
        remove,
        loading: !done,
    };
};

export default useTagging;
