// React
import React from 'react';

// Packages
import t from 'prop-types';
import cc from 'classcat';
// Utilities
import { useLabels, useTagging, useFormat } from 'utilities/hooks';

// Components
import Pill from 'components/pill';
import Card from 'components/_cardContentElements';
import Tags from 'components/tags';

const EmployeesFundedComponent = ({
    item,
    theme = 'teal',
    utilities,
    wrap = false,
}) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { getValueLabel, object, label } = useLabels();
    const tagging = useTagging({
        relationKey: 'Initiative_Employee_Funded__c',
    });
    const format = useFormat();

    // ///////////////////
    // DATA
    // ///////////////////

    const gender = `${getValueLabel(
        'Initiative_Employee_Funded__c.Gender__c',
        item?.Gender__c
    )}${item?.Gender_Other__c ? ` (${item?.Gender_Other__c})` : ''}`;

    // Employees Funded Fundings
    const employeeFundedFundings = utilities.employeeFundedFundings.getFromEmployeesFundedId(
        item.Id
    );

    // ///////////////////
    // THEME
    // ///////////////////

    const isTeal = theme === 'teal';
    const isBlue = theme === 'blue';

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <Card.CardContentWrapper
            {...{
                wrap,
            }}>
            {/* Role */}
            <Card.CardContentSection
                {...{
                    theme,
                    wrap,
                    title: object.label(
                        'Initiative_Employee_Funded__c.Role_Type__c'
                    ),
                }}>
                <Pill className="mt-4" theme={theme}>
                    {getValueLabel(
                        'Initiative_Employee_Funded__c.Role_Type__c',
                        item?.Role_Type__c
                    )}
                </Pill>
            </Card.CardContentSection>

            {/* Gender */}
            {item?.Gender__c && (
                <Card.CardContentSection
                    {...{
                        theme,
                        wrap,
                        title: object.label(
                            'Initiative_Employee_Funded__c.Gender__c'
                        ),
                    }}>
                    <p>{gender}</p>
                </Card.CardContentSection>
            )}

            {/* Job title */}
            {item?.Job_Title__c && (
                <Card.CardContentSection
                    {...{
                        theme,
                        wrap,
                        title: object.label(
                            'Initiative_Employee_Funded__c.Job_Title__c'
                        ),
                    }}>
                    <p>{item?.Job_Title__c}</p>
                </Card.CardContentSection>
            )}

            {/* Academic Title */}
            {item?.Academic_Title__c && (
                <Card.CardContentSection
                    {...{
                        theme,
                        wrap,
                        title: object.label(
                            'Initiative_Employee_Funded__c.Academic_Title__c'
                        ),
                    }}>
                    <p>{item?.Academic_Title__c}</p>
                </Card.CardContentSection>
            )}

            {/* Working agreement */}
            {item?.Working_Agreement__c && (
                <Card.CardContentSection
                    {...{
                        theme,
                        wrap,
                        title: object.label(
                            'Initiative_Employee_Funded__c.Working_Agreement__c'
                        ),
                    }}>
                    <p>{item?.Working_Agreement__c}</p>
                </Card.CardContentSection>
            )}

            {/* Responsible organisation */}
            {item?.Responsible_Organisation__c && (
                <Card.CardContentSection
                    {...{
                        theme,
                        wrap,
                        title: object.label(
                            'Initiative_Employee_Funded__c.Responsible_Organisation__c'
                        ),
                    }}>
                    <p>{item?.Responsible_Organisation__c}</p>
                </Card.CardContentSection>
            )}

            {/* Education */}
            {item?.Education__c && (
                <Card.CardContentSection
                    {...{
                        theme,
                        wrap,
                        title: object.label(
                            'Initiative_Employee_Funded__c.Education__c'
                        ),
                    }}>
                    <p>{item?.Education__c}</p>
                </Card.CardContentSection>
            )}

            {/* Education details */}
            {item?.Education_Details__c && (
                <Card.CardContentSection
                    {...{
                        theme,
                        wrap,
                        title: object.label(
                            'Initiative_Employee_Funded__c.Education_Details__c'
                        ),
                    }}>
                    <p>{item?.Education_Details__c}</p>
                </Card.CardContentSection>
            )}

            {/* ORCID */}
            {item?.ORCID__c && (
                <Card.CardContentSection
                    {...{
                        theme,
                        wrap,
                        title: object.label(
                            'Initiative_Employee_Funded__c.ORCID__c'
                        ),
                    }}>
                    <p>{item?.ORCID__c}</p>
                </Card.CardContentSection>
            )}

            {/* Start date / End date  */}
            <Card.CardContentDates
                {...{
                    theme,
                    startDate: item?.Start_Date__c,
                    startDateTitle: object.label(
                        'Initiative_Employee_Funded__c.Start_Date__c'
                    ),
                    endDate: item?.End_Date__c,
                    endDateTitle: object.label(
                        'Initiative_Employee_Funded__c.End_Date__c'
                    ),
                }}
            />

            {/* Employee funded fundings */}
            {employeeFundedFundings.length > 0 && (
                <Card.CardContentSection
                    {...{
                        theme,
                        title: object.label(
                            'Initiative_Employee_Funded__c.SupportedByFunding'
                        ),
                    }}>
                    <div className="flex flex-col gap-8 mt-8">
                        {employeeFundedFundings.map(x => {
                            const funding = utilities.fundings.get(
                                x?.Initiative_Funding__c
                            );
                            const funders = utilities.funders.getFromFundingId(
                                x?.Initiative_Funding__c
                            );
                            return (
                                <div
                                    key={x.Id}
                                    className={cc([
                                        'flex flex-col border-2 rounded-8 p-16',
                                        {
                                            'border-blue-10': isBlue,
                                            'border-teal-10': isTeal,
                                        },
                                    ])}>
                                    <span
                                        className={cc([
                                            't-sh6',
                                            {
                                                'text-blue-60': isBlue,
                                                'text-teal-60': isTeal,
                                            },
                                        ])}>
                                        {getValueLabel(
                                            'Initiative_Funding__c.Funding_Type__c',
                                            funding?.Funding_Type__c
                                        )}
                                    </span>
                                    <span
                                        className={cc([
                                            't-h5',
                                            {
                                                'text-blue-100': isBlue,
                                                'text-teal-100': isTeal,
                                            },
                                        ])}>
                                        {`${
                                            funding.Funding_Title__c
                                                ? `${funding.Funding_Title__c} - `
                                                : ''
                                        }${format.funderNames(funders)}`}
                                    </span>
                                    <span
                                        className={cc([
                                            't-sh6',
                                            {
                                                'text-blue-60': isBlue,
                                                'text-teal-60': isTeal,
                                            },
                                        ])}>
                                        {x?.Percent_Funded__c}{' '}
                                        {label(
                                            'SupportedByFundingPercentageFte'
                                        )}
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                </Card.CardContentSection>
            )}

            {/* Tags */}
            {tagging?.getCurrentTags(item).length > 0 && (
                <Card.CardContentSection
                    {...{
                        theme,
                        title: label('DetailsTagsEmployeesFunded'),
                    }}>
                    <Tags
                        {...{
                            theme,
                            tags: tagging?.getCurrentTags(item),
                            className: 'mt-4',
                        }}
                    />
                </Card.CardContentSection>
            )}
        </Card.CardContentWrapper>
    );
};

EmployeesFundedComponent.propTypes = {
    description: t.string,
    location: t.string,
};

EmployeesFundedComponent.defaultProps = {};

export default EmployeesFundedComponent;
