// React
import React, { useEffect } from 'react';

// Packages
import cc from 'classcat';
import Link from 'next/link';
import { useRouter } from 'next/router';

// Utilities
import { useLayoutStore } from 'utilities/store';
import { useLabels, useModalState, useUser } from 'utilities/hooks';
import { ACCOUNTS } from 'utilities/configuration/permission';

// Components
import MainNavigationItem from 'components/_layout/mainNavigationItem';
import LanguageModal from 'components/_modals/languageModal';
import Permission from 'components/permission';

// Icons
import {
    FiHome,
    FiHeart,
    FiFileText,
    FiPieChart,
    FiCompass,
    FiArchive,
} from 'react-icons/fi';

// Images
import FGLogoMark from 'assets/images/fg-logo-mark-small.svg';
import FGLogo from 'assets/images/fg-logo.svg';

// Data
import languages from 'utilities/configuration/languages';

const MainNavigationComponent = () => {
    // ///////////////////
    // STORES
    // ///////////////////

    const { mainNavigationWide, mainNavigationActive } = useLayoutStore();

    // ///////////////////
    // HOOKS
    // ///////////////////

    const { label } = useLabels();
    const { modalOpen, modalClose, modalState } = useModalState();
    const router = useRouter();
    const { user, getUserAccountType } = useUser();

    // ///////////////////
    // DATA
    // ///////////////////

    const Flag = languages[router.locale].Flag;

    function hrefImportExport() {
        if (getUserAccountType() === ACCOUNTS.super) {
            return '/import/initiatives';
        }

        if (user?.isExcelImportEnabledForContact) {
            return '/import/initiatives';
        }

        if (user?.isExcelExportEnabledForContact) {
            return '/export/reports';
        }

        if (user?.isJSONExportEnabledForContact) {
            return '/export/initiatives';
        }

        return null;
    }

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <div
            style={{ willChange: 'transform' }}
            className={cc([
                'flex print:hidden transform-gpu left-0  flex-col fixed top-0 h-screen bg-white border-r z-main-nav border-blue-20 transition-medium',
                {
                    'w-nav-wide page-px flex justify-start delay-150': mainNavigationWide,
                    'w-nav-narrow px-12 justify-center': !mainNavigationWide,
                },
                {
                    'pointer-events-auto': mainNavigationActive,
                    '-translate-x-full pointer-events-none': !mainNavigationActive,
                },
            ])}>
            {/* Logo */}
            <div
                className={cc([
                    'flex items-center h-header print:hidden',
                    {
                        'justify-start': mainNavigationWide,
                        'justify-center': !mainNavigationWide,
                    },
                ])}>
                <Link
                    href="/initiative-search"
                    className="relative block w-full h-20">
                    <FGLogo
                        className={cc([
                            'text-blue-300 fill-current absolute transition-medium transform-gpu',
                            {
                                'opacity-0 pointer-events-none': !mainNavigationWide,
                                'opacity-100 delay-50': mainNavigationWide,
                            },
                        ])}
                    />
                    <FGLogoMark
                        className={cc([
                            'text-blue-300 fill-current absolute transition-medium transform-gpu left-1/2 -translate-x-1/2',
                            {
                                'opacity-0 pointer-events-none': mainNavigationWide,
                                'opacity-100 delay-50': !mainNavigationWide,
                            },
                        ])}
                    />
                </Link>
            </div>

            {/* Navigation */}
            <div className="pt-12 space-y-8">
                <MainNavigationItem
                    {...{
                        Icon: FiHeart,
                        label: label('MainNavigationInitiatives'),
                        showLabel: mainNavigationWide,
                        href: '/initiative-search',
                        additionalHrefs: ['/overview'],
                    }}
                />
                <MainNavigationItem
                    {...{
                        Icon: FiFileText,
                        label: label('MainNavigationReports'),
                        showLabel: mainNavigationWide,
                        href: '/report-search',
                    }}
                />
                <Permission
                    {...{
                        rules: ['funder', 'super', 'grantee'],
                    }}>
                    <MainNavigationItem
                        {...{
                            Icon: FiPieChart,
                            label: label('MainNavigationDashboard'),
                            showLabel: mainNavigationWide,
                            href: '/dashboard/initiatives',
                        }}
                    />
                </Permission>
                <Permission
                    {...{
                        rules: ['funder', 'super'],
                    }}>
                    <MainNavigationItem
                        {...{
                            Icon: FiCompass,
                            label: label('MainNavigationPortfolio'),
                            showLabel: mainNavigationWide,
                            href: '/portfolio',
                        }}
                    />
                </Permission>

                {hrefImportExport() && (
                    <MainNavigationItem
                        {...{
                            Icon: FiArchive,
                            label: label('MainNavigationDataExport'),
                            showLabel: mainNavigationWide,
                            href: hrefImportExport(),
                        }}
                    />
                )}
            </div>

            {/* Language */}
            <div className="mt-auto">&nbsp;</div>
            {process.env.NEXT_PUBLIC_ENVIRONMENT !== 'PRODUCTION' && (
                <div
                    role="button"
                    onClick={modalOpen}
                    className={cc([
                        'block relative py-10 w-full h-32 mt-auto mb-24 text-blue-300 bg-white border transition-default hover:text-blue-300 hover:border-blue-40 rounded-4 border-blue-20 focus:outline-none active:outline-none',
                    ])}>
                    <Flag
                        className={cc([
                            'absolute -translate-x-1/2 transform transition-medium',
                            {
                                'left-16': mainNavigationWide,
                                'left-18': !mainNavigationWide,
                            },
                        ])}
                    />
                    <span
                        className={cc([
                            'absolute top-5 left-32 transition-medium',
                            {
                                'opacity-0 ': !mainNavigationWide,
                                'opacity-100 delay-200': mainNavigationWide,
                            },
                        ])}>
                        {label(languages[router.locale].label)}
                    </span>
                </div>
            )}
            <LanguageModal {...{ ...modalState, onClose: modalClose }} />
        </div>
    );
};

MainNavigationComponent.propTypes = {};

MainNavigationComponent.defaultProps = {};

export default MainNavigationComponent;
