// React
import React from 'react';

// Packages
import t from 'prop-types';

// Utilities
import { useLabels } from 'utilities/hooks';

// Components
import Pill from 'components/pill';
import Card from 'components/_cardContentElements';

const EmployeesFundedComponent = ({ item, theme = 'teal', wrap = false }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { object, getValueLabel } = useLabels();

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <Card.CardContentWrapper
            {...{
                wrap,
            }}>
            {/* Importance */}
            <Card.CardContentSection
                {...{
                    theme,
                    wrap,
                    title: object.label(
                        'Initiative_Activity_Regulation__c.Importance__c'
                    ),
                }}>
                <Pill className="mt-4" theme={theme}>
                    {getValueLabel(
                        'Initiative_Activity_Regulation__c.Importance__c',
                        item?.Importance__c
                    )}
                </Pill>
            </Card.CardContentSection>

            {/* Issuing body */}
            <Card.CardContentSection
                {...{
                    theme,
                    wrap,
                    title: object.label(
                        'Initiative_Activity_Regulation__c.Issuing_Body__c'
                    ),
                }}>
                <p>{item?.Issuing_Body__c}</p>
            </Card.CardContentSection>

            {/* Description */}
            <Card.CardContentSection
                {...{
                    theme,
                    wrap,
                }}>
                <p>{item?.Description__c}</p>
            </Card.CardContentSection>

            {/* Start date / End date  */}
            <Card.CardContentDates
                {...{
                    theme,
                    startDate: item?.Date_Applied__c,
                    startDateTitle: object.label(
                        'Initiative_Activity_Regulation__c.Date_Applied__c'
                    ),
                    endDate: item?.Date_Received__c,
                    endDateTitle: object.label(
                        'Initiative_Activity_Regulation__c.Date_Received__c'
                    ),
                }}
            />
        </Card.CardContentWrapper>
    );
};

EmployeesFundedComponent.propTypes = {
    description: t.string,
    location: t.string,
};

EmployeesFundedComponent.defaultProps = {};

export default EmployeesFundedComponent;
