// React
import React from 'react';

// Packages
import t from 'prop-types';

// Utilities
import { useLabels, useFormat } from 'utilities/hooks';

// Components
import Card from 'components/_cardContentElements';

const ResultsInventionDisclosure = ({ item, theme = 'teal', wrap = false }) => {
    // ///////////////////
    // DATA
    // ///////////////////

    const { Description__c } = item;

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <Card.CardContentWrapper
            {...{
                wrap,
            }}>
            {/* Description */}
            {Description__c && (
                <Card.CardContentSection
                    {...{
                        theme,
                        wrap,
                    }}>
                    <p>{Description__c}</p>
                </Card.CardContentSection>
            )}

            {/* Details */}
            <ResultsInventionDisclosureDetails {...{ item, theme }} />
        </Card.CardContentWrapper>
    );
};

const ResultsInventionDisclosureDetails = ({ item, theme }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { object } = useLabels();
    const format = useFormat();

    // ///////////////////
    // DATA
    // ///////////////////

    const { Year__c, Ownership__c, Commercialisation__c } = item;

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <>
            {/* Various Info */}
            {[Year__c, Ownership__c, Commercialisation__c].some(x => x) && (
                <Card.CardContentPairBox>
                    {Year__c && (
                        <Card.CardContentPair
                            {...{
                                theme,
                                label: object.label(
                                    'Initiative_Result__c.Year__c___Invention_Disclosure'
                                ),
                                value: format.year(Year__c),
                            }}
                        />
                    )}

                    {Ownership__c && (
                        <Card.CardContentPair
                            {...{
                                theme,
                                label: object.label(
                                    'Initiative_Result__c.Ownership__c'
                                ),
                                value: format.booleanToWords(Ownership__c),
                            }}
                        />
                    )}

                    {Commercialisation__c && (
                        <Card.CardContentPair
                            {...{
                                theme,
                                label: object.label(
                                    'Initiative_Result__c.Commercialisation__c'
                                ),
                                value: format.booleanToWords(
                                    Commercialisation__c
                                ),
                            }}
                        />
                    )}
                </Card.CardContentPairBox>
            )}
        </>
    );
};

ResultsInventionDisclosure.propTypes = {
    item: t.object,
};

ResultsInventionDisclosure.defaultProps = {};

export { ResultsInventionDisclosureDetails };
export default ResultsInventionDisclosure;
