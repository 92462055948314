import create from 'zustand';

const useFilterReportExportSearch = create((set, get) => ({
    initial: null,
    setInitial: initial => set(() => ({ initial })),

    filtered: null,
    setFiltered: filtered => set(() => ({ filtered })),

    filter: {
        text: '',
        category: [],
        status: [],
        type: [],
        foundation: [],
        year: [],
        tags: [],
    },
    setFilter: filter => set(() => ({ filter })),

    hasFilter: () => {
        const { filter } = get();
        if (filter === null) {
            return false;
        }

        const { text, category, status, type, foundation, year, tags } = filter;

        return (
            text !== '' ||
            category.length > 0 ||
            status.length > 0 ||
            type.length > 0 ||
            foundation.length > 0 ||
            year.length > 0 ||
            tags.length > 0
        );
    },

    reset: () =>
        set(() => ({
            initial: null,
            filtered: null,
            filter: {
                text: '',
                category: [],
                status: [],
                type: [],
                foundation: [],
                year: [],
                tags: [],
            },
        })),
}));

export { useFilterReportExportSearch };
