// React
import React from 'react';

// Packages
import t from 'prop-types';
import { useLabels, useFormat } from 'utilities/hooks';

// Utilities

// Components
import Card from 'components/_cardContentElements';

const ResultsPatentsComponent = ({ item, theme = 'teal', wrap = false }) => {
    // ///////////////////
    // DATA
    // ///////////////////

    const { Description__c } = item;

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <Card.CardContentWrapper
            {...{
                wrap,
            }}>
            {/* Description */}
            {Description__c && (
                <Card.CardContentSection
                    {...{
                        theme,
                        wrap,
                    }}>
                    <p>{Description__c}</p>
                </Card.CardContentSection>
            )}

            {/* Details */}
            <ResultsPatentsDetails {...{ item, theme }} />
        </Card.CardContentWrapper>
    );
};

const ResultsPatentsDetails = ({ item, theme }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { object } = useLabels();
    const format = useFormat();

    // ///////////////////
    // DATA
    // ///////////////////

    const { Patent_Id__c, Result_Start_Date__c, Result_End_Date__c } = item;

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <>
            {/* Various Info */}
            {[Patent_Id__c].some(x => x) && (
                <Card.CardContentPairBox>
                    {Patent_Id__c && (
                        <Card.CardContentPair
                            {...{
                                theme,
                                label: object.label(
                                    'Initiative_Result__c.Patent_Id__c'
                                ),
                                value: Patent_Id__c,
                            }}
                        />
                    )}
                </Card.CardContentPairBox>
            )}

            {/* Start date / End date  */}
            {Result_Start_Date__c && (
                <div className="flex !mt-24 space-x-16">
                    <Card.CardContentSectionBox
                        {...{
                            theme,
                            title: object.label(
                                'Initiative_Result__c.Result_Start_Date__c___Patent'
                            ),
                        }}>
                        <p>{format.date(Result_Start_Date__c)}</p>
                    </Card.CardContentSectionBox>
                    <Card.CardContentSectionBox
                        {...{
                            theme,
                            title: object.label(
                                'Initiative_Result__c.Result_End_Date__c___Patent'
                            ),
                        }}>
                        <p>
                            {Result_End_Date__c
                                ? format.date(Result_End_Date__c)
                                : '-'}
                        </p>
                    </Card.CardContentSectionBox>
                </div>
            )}
        </>
    );
};

ResultsPatentsComponent.propTypes = {
    item: t.object,
};

ResultsPatentsComponent.defaultProps = {};

export { ResultsPatentsDetails };
export default ResultsPatentsComponent;
