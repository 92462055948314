// React
import React from 'react';

// Packages
import t from 'prop-types';

// Utilities
import { useLabels, useTagging } from 'utilities/hooks';

// Components
import Card from 'components/_cardContentElements';
import Tags from 'components/tags';

const ActivityTagsComponent = ({ item, theme = 'teal' }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { label } = useLabels();
    const tagging = useTagging({
        relationKey: 'Initiative_Activity__c',
    });

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        tagging?.getCurrentTags(item).length > 0 && (
            <Card.CardContentSection
                {...{
                    theme,
                    title: label('DetailsTagsActivities'),
                }}>
                <Tags
                    {...{
                        theme,
                        tags: tagging?.getCurrentTags(item),
                        className: 'mt-4',
                    }}
                />
            </Card.CardContentSection>
        )
    );
};

ActivityTagsComponent.propTypes = {
    item: t.object,
};

ActivityTagsComponent.defaultProps = {};

export default ActivityTagsComponent;
