// React
import React from 'react';

// Packages
import t from 'prop-types';

// Utilities
import { useLabels, useFormat } from 'utilities/hooks';

// Components
import Card from 'components/_cardContentElements';

const ResultsKnowledgeComponent = ({ item, theme = 'teal', wrap = false }) => {
    // ///////////////////
    // DATA
    // ///////////////////

    const { Description__c } = item;

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <Card.CardContentWrapper
            {...{
                wrap,
            }}>
            {/* Description */}
            {Description__c && (
                <Card.CardContentSection
                    {...{
                        theme,
                        wrap,
                    }}>
                    <p>{Description__c}</p>
                </Card.CardContentSection>
            )}

            {/* Details */}
            <ResultsKnowledgeDetails {...{ item, theme }} />
        </Card.CardContentWrapper>
    );
};

const ResultsKnowledgeDetails = ({ item, theme }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { object, getValueLabel } = useLabels();
    const format = useFormat();

    // ///////////////////
    // DATA
    // ///////////////////

    const { URL__c, DOI__c, Availibility__c, Year__c } = item;

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <>
            {/* Various Info */}
            {[URL__c, Availibility__c].some(x => x) && (
                <Card.CardContentPairBox>
                    {URL__c && (
                        <Card.CardContentPair
                            {...{
                                theme,
                                label: object.label(
                                    'Initiative_Result__c.URL__c'
                                ),
                                value: URL__c,
                            }}
                        />
                    )}

                    {Availibility__c && (
                        <Card.CardContentPair
                            {...{
                                theme,
                                label: object.label(
                                    'Initiative_Result__c.Availibility__c'
                                ),
                                value: getValueLabel(
                                    'Initiative_Result__c.Availibility__c',
                                    Availibility__c
                                ),
                            }}
                        />
                    )}

                    {Year__c && (
                        <Card.CardContentPair
                            {...{
                                theme,
                                label: object.label(
                                    'Initiative_Result__c.Year__c___Knowledge'
                                ),
                                value: format.year(Year__c),
                            }}
                        />
                    )}

                    {DOI__c && (
                        <Card.CardContentPair
                            {...{
                                theme,
                                label: object.label(
                                    'Initiative_Result__c.DOI__c'
                                ),
                                value: DOI__c,
                            }}
                        />
                    )}
                </Card.CardContentPairBox>
            )}
        </>
    );
};

ResultsKnowledgeComponent.propTypes = {
    item: t.object,
};

ResultsKnowledgeComponent.defaultProps = {};

export { ResultsKnowledgeDetails };
export default ResultsKnowledgeComponent;
