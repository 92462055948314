// React
import React from 'react';

// Packages
import t from 'prop-types';

// Utilities
import { useLabels } from 'utilities/hooks';

// Components
import Card from 'components/_cardContentElements';
import Pill from 'components/pill';

const CollaboratorComponent = ({ item, theme = 'teal', wrap = false }) => {
    // ///////////////////
    // HOOKS
    // ///////////////////

    const { object } = useLabels();

    // ///////////////////
    // RENDER
    // ///////////////////

    return (
        <Card.CardContentWrapper
            {...{
                wrap,
            }}>
            {/* Description */}
            <Card.CardContentSection
                {...{
                    theme,
                    wrap,
                }}>
                <p>{item?.Description__c}</p>
            </Card.CardContentSection>

            {/* Description */}
            <Card.CardContentSection
                {...{
                    theme,
                    wrap,
                    title: object.label(
                        'Initiative_Collaborator__c.Input_Type__c'
                    ),
                }}>
                <div className="flex gap-8">
                    {item?.Input_Type__c?.split(';').map(x => (
                        <Pill key={x}>{x}</Pill>
                    ))}
                </div>
            </Card.CardContentSection>

            {/* Start date / End date  */}
            <Card.CardContentDates
                {...{
                    theme,
                    startDate: item?.Start_Date__c,
                    startDateTitle: object.label(
                        'Initiative_Collaborator__c.Start_Date__c'
                    ),
                    endDate: item?.End_Date__c,
                    endDateTitle: object.label(
                        'Initiative_Collaborator__c.End_Date__c'
                    ),
                }}
            />
        </Card.CardContentWrapper>
    );
};

CollaboratorComponent.propTypes = {
    item: t.object,
};

CollaboratorComponent.defaultProps = {};

export default CollaboratorComponent;
